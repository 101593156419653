<template>
  <PageContainer>
    <PageGrid>
      <template #default="{ colSpacing }">
        <PageGridColumn :class="colSpacing">
          <div class="mb-8 mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-4">
            <!-- CMS Config Card -->
            <AdminCard>
              <template #default="{ titleClasses }">
                <div class="space-y-2">
                  <div>
                    <div
                      :class="titleClasses"
                      v-text="t('cardTitle.configName')"
                    />
                    <p class="text-neutral-dark-1 text-lg font-semibold">
                      {{ config?.name }}
                    </p>
                  </div>
                  <div>
                    <div
                      :class="titleClasses"
                      v-text="t('cardTitle.configId')"
                    />
                    <p class="text-neutral-dark-1 text-lg font-semibold">
                      {{ config?.id }}
                    </p>
                  </div>
                </div>
              </template>
            </AdminCard>

            <!-- Logo Card -->
            <AdminCard :title="t('cardTitle.logo')">
              <template #default="{ titleClasses }">
                <div :class="titleClasses" v-text="t('cardTitle.logo')" />
                <div class="flex h-20 w-full items-center justify-center">
                  <PageLogo link="#" :preload="false" class="h-auto w-full" />
                </div>
              </template>
            </AdminCard>

            <!-- Designs Card -->
            <AdminCard>
              <template #default="{ titleClasses }">
                <div
                  :class="titleClasses"
                  v-text="
                    t('cardTitle.designs', {
                      amount: config?.designs?.length || 0,
                    })
                  "
                />

                <div class="space-y-0.5">
                  <button
                    v-for="design in config?.designs"
                    :key="design.id"
                    class="text-neutral-dark-1 block text-sm"
                    @click="previewDesign(design)"
                  >
                    {{ design?.description }}
                  </button>
                </div>
              </template>
            </AdminCard>

            <!-- Content Card -->
            <AdminCard :title="t('cardTitle.content')" class="col-span-full">
              <NuxtLink
                to="/admin/raffles"
                class="text-primary hover:underline"
              >
                {{ t('link.manageRaffles') }}
              </NuxtLink>
            </AdminCard>
          </div>
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import useAdmin from '@builder/composables/use-admin'
import type { LayoutKey } from '#build/types/layouts'

const { t } = useI18n()
const { setPage } = useAdmin()
const { config } = useCms()
const { design, applyDesign } = useDesign()

definePageMeta({
  layout: 'admin' as LayoutKey,
})

defineOptions({
  name: 'PageAdminDashboard',
})

setPage({
  title: t('page.title'),
  breadcrumbs: [{ path: '/admin', title: t('breadcrumb.admin') }],
})

function previewDesign(newDesign: any) {
  design.value = newDesign
  applyDesign()
}
</script>

<i18n>
de:
  link:
    manageRaffles: "Verlosungen verwalten"
  cardTitle:
    content: "Inhalt"
    configId: "Config ID"
    configName: "Name"
    logo: "Logo"
    designs: "Designs: {amount}"
  page:
    title: "Dashboard"
  breadcrumb:
    dashboard: "Dashboard"
    admin: "Admin"
</i18n>
