<template>
  <PageContainer>
    <PageGrid>
      <template #default="{ colSpacing }">
        <PageGridColumn :class="colSpacing">
          <RaffleForm @cancel="router.back()" @close="router.back()" />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts'
import useAdmin from '@builder/composables/use-admin'

const router = useRouter()
const { t } = useI18n()
const { setPage } = useAdmin()

definePageMeta({
  layout: 'admin' as LayoutKey,
})

setPage({
  title: t('page.title'),
  breadcrumbs: [
    { path: '/admin', title: t('breadcrumb.admin') },
    { path: '/admin/raffles', title: t('breadcrumb.raffles') },
    { path: '/admin/raffle/add', title: t('breadcrumb.add') },
  ],
})

defineOptions({
  name: 'PageRaffleAdd',
})
</script>

<i18n>
de:
  page:
    title: "Verlosung hinzufügen"
  breadcrumb:
    admin: "Admin"
    raffles: "Verlosungen"
    add: "Hinzufügen"
</i18n>
