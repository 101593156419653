<template>
  <PageContainer>
    <PageGrid>
      <template #default="{ colSpacing }">
        <PageGridColumn :class="colSpacing">
          <RaffleForm
            :raffle
            @cancel="router.back()"
            @close="router.push(`/admin/raffle/${raffle?.id}`)"
          />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts'
import useAdmin from '@builder/composables/use-admin'
import useRaffleAdmin from '@builder/composables/use-raffle-admin'

const route = useRoute()
const router = useRouter()
const { setPage } = useAdmin()

const raffleId = ref(route.params.id as string)
const { raffle } = await useRaffleAdmin({ variables: { id: raffleId.value } })

const { t } = useI18n()

definePageMeta({
  layout: 'admin' as LayoutKey,
})

setPage({
  title: t('page.title', { name: raffle.value?.name }),
  breadcrumbs: [
    { path: '/admin', title: t('breadcrumb.admin') },
    { path: '/admin/raffles', title: t('breadcrumb.raffles') },
    { path: `/admin/raffle/${raffle.value?.id}`, title: raffle.value?.name },
    {
      path: `/admin/raffle/${raffle.value?.id}/edit`,
      title: t('breadcrumb.edit'),
    },
  ],
})

defineOptions({
  name: 'PageRaffleEdit',
})
</script>

<i18n>
de:
  page:
    title: "Verlosung {name} bearbeiten"
  breadcrumb:
    admin: "Admin"
    raffles: "Verlosungen"
    raffle: "Verlosung {name}"
    edit: "Bearbeiten"
</i18n>
