<template>
  <Teleport to="body">
    <div
      :class="cssClasses.container"
      :style="cssStyles.container"
      @click.self="close"
    >
      <div
        :class="cssClasses.modal"
        class="relative w-full max-w-xl rounded-lg bg-light p-0 shadow-xl dark:bg-dark"
      >
        <!-- Header -->
        <div class="relative border-b border-neutral-light-3 p-2 dark:border-neutral-dark-1 mb-4">
          <h2 class="pr-8 text-2xl font-bold" v-text="pageTitle" />

          <Button
            :theme="BUTTON_THEME.TERTIARY"
            :size="BUTTON_SIZE.SM"
            :title="t('btn.closeThisWindow')"
            :icon="SVG_ICON.CLOSE"
            :class="cssClasses.close"
            @click="close"
          />
        </div>

        <!-- Content -->
        <RaffleForm :raffle @close="close" />
      </div>
      <div :class="cssClasses.backdrop" @click.self="close" />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import type { Raffle } from '#gql/default'
import useModal from '@base/composables/use-modal'
import { BUTTON_THEME, BUTTON_SIZE } from '@base/constants/button'
import { SVG_ICON } from '@assets/constants/inline-svg'

interface Props {
  raffle?: Raffle
}

const { raffle } = defineProps<Props>()
const emit = defineEmits(['close'])

const { t } = useI18n()
const { close, open, cssClasses, cssStyles } = useModal({
  onClose: () => emit('close')
})
open()

const pageTitle = computed(() => !raffle ? t('page.title.add') : t('page.title.edit', { name: raffle.name }))

defineOptions({
  name: 'RaffleModal',
})
</script>

<i18n>
de:
  page:
    title:
      add: "Gewinnspiel hinzufügen"
      edit: "{name} bearbeiten"
  btn:
    closeThisWindow: "Fenster schließen"
</i18n>
