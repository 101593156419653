<template>
  <AdminCard :title="raffle.name">
    <template #content>
      <div class="flex items-center justify-between">
        <div>
          <p class="text-neutral text-sm">
            {{ d(raffle.from, 'short') }} - {{ d(raffle.to, 'short') }}
          </p>
        </div>
        <div class="flex gap-2">
          <NuxtLink
            :to="
              localeRoute({
                name: ADMIN_ROUTE_NAMES.RAFFLE_DETAIL,
                params: { id: raffle.id },
              })
            "
            :title="t('btn.show')"
            class="button button-secondary button-sm"
          >
            <Icon name="material-symbols:visibility-outline" />
          </NuxtLink>
          <NuxtLink
            :to="
              localeRoute({
                name: ADMIN_ROUTE_NAMES.RAFFLE_EDIT,
                params: { id: raffle.id },
              })
            "
            :title="t('btn.edit')"
            class="button button-secondary button-sm"
          >
            <Icon name="material-symbols:edit-outline" />
          </NuxtLink>
        </div>
      </div>
    </template>
  </AdminCard>
</template>

<script setup lang="ts">
import type { Raffle } from '#gql/default'
import { ADMIN_ROUTE_NAMES } from '@builder/constants/routes'

interface Props {
  raffle: Raffle
}

const { raffle } = defineProps<Props>()

const { t, d } = useI18n()
const localeRoute = useLocaleRoute()
const emit = defineEmits(['edit'])

defineOptions({
  name: 'RaffleListItem',
})
</script>

<i18n>
de:
  pickWinners: "Gewinner auswählen"
  winnersSelected: "Gewinner wurden ausgewählt"
  participants: "Teilnehmer"
  btn:
    edit: "Bearbeiten"
    show: "Anzeigen"
</i18n>
