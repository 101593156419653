export default function useDetectMobile() {
  // Get User-Agent safely for both client and server
  const headers = useRequestHeaders(['user-agent'])
  const userAgent = import.meta.client ? navigator.userAgent : (headers['user-agent'] ?? '')
  const isMobile = /mobile/i.test(userAgent);

  return {
    isMobile,
  }
}
