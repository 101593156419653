<template>
  <PageContainer>
    <PageGrid>
      <template #default="{ colSpacing }">
        <PageGridColumn :class="colSpacing">
          <!-- Empty State -->
          <div
            v-if="!raffles?.length"
            class="flex flex-col items-center justify-center space-y-4 rounded-lg border p-8"
          >
            <p class="text-neutral text-lg">{{ t('errors.noRaffles') }}</p>
          </div>

          <!-- Raffle List -->
          <div v-else class="space-y-4">
            <RaffleListItem
              v-for="raffle in raffles"
              :key="raffle.id"
              :raffle
            />
          </div>

          <div class="flex justify-end">
            <NuxtLink
              :to="localeRoute({ name: ADMIN_ROUTE_NAMES.RAFFLE_ADD })"
              class="button button-base button-primary"
            >
              {{ t('btn.addRaffle') }}
            </NuxtLink>
          </div>

          <!-- Add/Edit Modal -->
          <RaffleModal
            v-if="isRaffleModalVisible"
            @close="isRaffleModalVisible = false"
          />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import { ADMIN_ROUTE_NAMES } from '@builder/constants/routes'
import useRafflesAdmin from '@builder/composables/use-raffles-admin'
import useAdmin from '@builder/composables/use-admin'
import useCms from '@cms/composables/use-cms'

const { config } = useCms()
const { setPage } = useAdmin()
const { t } = useI18n()
const localeRoute = useLocaleRoute()
const { raffles } = await useRafflesAdmin({
  variables: { cmsConfigId: config.value?.id ?? '', first: 10, page: 1 },
})

const isRaffleModalVisible = ref(false)

definePageMeta({
  layout: 'admin',
})

defineOptions({
  name: 'PageRaffles',
})

setPage({
  title: t('page.title'),
  breadcrumbs: [
    { path: '/admin', title: t('breadcrumb.admin') },
    { path: '/admin/raffles', title: t('breadcrumb.raffles') },
  ],
})
</script>

<i18n>
de:
  page:
    title: "Verlosungen"
  breadcrumb:
    admin: "Admin"
    raffles: "Verlosungen"
  errors:
    noRaffles: "Noch keine Verlosungen vorhanden"
  btn:
    addRaffle: "Verlosung hinzufügen"
</i18n>
