<template>
  <PageContainer>
    <PageGrid>
      <template #default="{ colSpacing }">
        <PageGridColumn :class="colSpacing">
          <!-- Raffle Details Card -->
          <div class="mb-8 mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-4">
            <!-- Date Range Card -->
            <AdminCard>
              <template #default="{ titleClasses }">
                <div class="space-y-2">
                  <div>
                    <div :class="titleClasses" v-text="t('cardTitle.period')" />
                    <p class="text-neutral-dark-1 text-lg font-semibold">
                      {{ d(raffle?.from, 'short') }} -
                      {{ d(raffle?.to, 'short') }}
                    </p>
                  </div>

                  <div>
                    <div :class="titleClasses" v-text="t('cardTitle.status')" />
                    <p
                      class="text-lg font-semibold"
                      :class="{ 'text-green-600': raffle?.active }"
                      v-text="
                        raffle?.active
                          ? t('raffle.status.active')
                          : t('raffle.status.inactive')
                      "
                    />
                  </div>
                </div>
              </template>
            </AdminCard>

            <!-- Prizes -->
            <AdminCard>
              <template #title>
                <Button
                  :theme="BUTTON_THEME.TRANSPARENT"
                  :size="BUTTON_SIZE.SM"
                  :title="t('raffle.managePrizes')"
                  :text="
                    t('cardTitle.prizes', {
                      amount: raffle?.prizes?.paginatorInfo?.total || 0,
                    })
                  "
                  class="hover:text-primary hover:underline"
                  @click="(currentPrize = null), (isPrizesModalVisible = true)"
                />
              </template>
              <template #content>
                <Button
                  v-for="prize in raffle?.prizes?.data"
                  :key="prize.id"
                  :theme="BUTTON_THEME.TRANSPARENT"
                  :size="BUTTON_SIZE.SM"
                  :title="
                    t('btn.editPrize', {
                      name: prize.name,
                    })
                  "
                  :text="`${prize.quantity}x ${prize.name}`"
                  class="text-neutral-dark-1 hover:text-primary !h-auto text-sm hover:underline"
                  @click="(currentPrize = prize), (isPrizesModalVisible = true)"
                />
              </template>

              <template #actions>
                <RafflePrizesModal
                  v-if="isPrizesModalVisible"
                  :raffle
                  :prize="currentPrize"
                  @close="isPrizesModalVisible = false"
                />
              </template>
            </AdminCard>

            <!-- Participants / Winners Card -->
            <AdminCard>
              <template #default="{ titleClasses }">
                <Button
                  :theme="BUTTON_THEME.TRANSPARENT"
                  :size="BUTTON_SIZE.SM"
                  :title="t('raffle.manageParticipants')"
                  :text="t('cardTitle.participants', {
                    amount: raffle?.entries?.paginatorInfo?.total || 0,
                  })"
                  class="hover:text-primary hover:underline"
                  @click="isParticipantsModalVisible = true"
                />
                <div
                  :class="titleClasses"
                  v-text="t('cardTitle.winners', {
                    amount: raffle?.winners?.paginatorInfo?.total || 0,
                  })"
                />
              </template>
            </AdminCard>

            <RaffleParticipantsModal
              v-if="isParticipantsModalVisible"
              :raffle="raffle"
              @close="isParticipantsModalVisible = false"
            />

            <AdminCard
              :title="t('cardTitle.termsOfService')"
              class="col-span-full"
            >
              <div
                class="prose dark:prose-invert mt-2 !max-w-none"
                v-html="raffle?.termsOfService"
              />
            </AdminCard>
          </div>
        </PageGridColumn>
        <PageGridColumn>
          <Button
            :text="t('btn.edit')"
            @click="router.push(`/admin/raffle/${raffle?.id}/edit`)"
          />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import { BUTTON_THEME, BUTTON_SIZE } from '@base/constants/button'
import useAdmin from '@builder/composables/use-admin'
import type { LayoutKey } from '#build/types/layouts'
import type { RafflePrize } from '#gql/default'
import useRaffleAdmin from '@builder/composables/use-raffle-admin'

const route = useRoute()
const router = useRouter()

const raffleId = ref(route.params.id as string)
const { raffle } = await useRaffleAdmin({ variables: { id: raffleId.value } })

const { setPage } = useAdmin()

const { t, d } = useI18n()
const isPrizesModalVisible = ref(false)
const isParticipantsModalVisible = ref(false)
const currentPrize = ref<RafflePrize | null>(null)

definePageMeta({
  layout: 'admin' as LayoutKey,
  path: '/admin/raffle/:id',
})

setPage({
  title: t('page.title', { name: raffle.value?.name }),
  breadcrumbs: [
    { path: '/admin', title: t('breadcrumb.admin') },
    { path: '/admin/raffles', title: t('breadcrumb.raffles') },
    { path: `/admin/raffle/${raffle.value?.id}`, title: raffle.value?.name },
  ],
})

defineOptions({
  name: 'PageRaffleDetail',
})
</script>

<i18n>
de:
  btn:
    edit: "Bearbeiten"
    editPrize: "Preis {name} bearbeiten"
  breadcrumb:
    admin: "Admin"
    raffles: "Verlosungen"
    raffle: "Verlosung {id}"
  cardTitle:
    prizes: "Preise: {amount}"
    winners: "Gewinner: {amount}"
    participants: "Teilnehmer: {amount}"
    status: "Status"
    period: "Zeitraum"
    dailyRaffle: "Tägliches Gewinnspiel"
    termsOfService: "Nutzungsbedingungen"
  raffle:
    managePrizes: "Preise verwalten / hinzufügen"
    pickWinners: "Gewinner auswählen"
    viewWinners: "Gewinner anzeigen"
    status:
      draft: "Entwurf"
      upcoming: "Bevorstehend"
      active: "Aktiv"
      ended: "Beendet"
    manageParticipants: "Teilnehmer verwalten"
  errors:
    pickWinners: "Fehler beim Auswählen der Gewinner"
  yes: "Ja"
  no: "Nein"
  page:
    title: "Verlosung {name}"
</i18n>
