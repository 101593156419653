interface BreadcrumbItem {
  title: string
  path: string
}

export default function useAdmin() {
  const title = useState('adminTitle', () => '')
  const breadcrumbItems = useState('adminBreadcrumbItems', () => [])


  function setPage(page: any) {
    title.value = page.title
    breadcrumbItems.value = page.breadcrumbs
  }

  return {
    breadcrumbItems,
    title,
    setPage
  }
}

