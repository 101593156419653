<template>
  <NuxtLink
    :class="{ logo: !$attrs.id }"
    :to="link ?? '/'"
    :title="config?.name"
  >
    <div
      v-if="currentSvgLogo"
      v-html="currentSvgLogo"
    />

    <NuxtImg
      v-else-if="currentLogo"
      :alt="config?.name"
      :src="currentLogo?.src"
      height="96"
      :preload
      :loading
      :format
    />

    <div v-else v-text="config?.name" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

defineOptions({
  name: 'PageLogo',
})

interface Props {
  link?: string
  preload?: boolean
  loading?: ImageLoading
  format?: ImageFileFormat
  responsive?: boolean
}

const {
  link,
  preload = false,
  responsive = false,
  loading = IMAGE_LOADING.LAZY,
  format = IMAGE_FILE_FORMAT.WEBP,
} = defineProps<Props>()

const { config } = useCms()
const { isMobile } = useDetectMobile()
const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndLarger = breakpoints.greaterOrEqual('lg')

const responsiveSvgLogo = computed(() => {
  if (!responsive) {
    return null
  }

  const mobileVariant = config.value?.svgLogoMobile
  const desktopVariant = config.value?.svgLogoDesktop

  if (import.meta.client) {
    return !lgAndLarger.value ? mobileVariant : desktopVariant
  }

  return isMobile ? mobileVariant : desktopVariant
})

const responsiveImageLogo = computed(() => {
  if (!responsive) {
    return null
  }

  const mobileVariant = config.value?.logoMobile
  const desktopVariant = config.value?.logoDesktop

  if (import.meta.client && mobileVariant && desktopVariant) {
    const variant = !lgAndLarger.value ? mobileVariant : desktopVariant
    return variant ? getImage(config.value, variant === mobileVariant ? 'logoMobile' : 'logoDesktop', config.value?.name) : null
  }

  return (mobileVariant && desktopVariant) ? getImage(config.value, isMobile ? 'logoMobile' : 'logoDesktop', config.value?.name) : null
})

const currentSvgLogo = computed(() =>
  responsiveSvgLogo.value ?? config.value?.svgLogo
)

const currentLogo = computed(() =>
  responsiveImageLogo.value ?? (config.value?.logo ? getImage(config.value, 'logo', config.value?.name) : null)
)
</script>
