<template>
  <div data-paginator class="space-y-8">
    <PaginatorNav
      v-if="topNav && paginatorInfo?.lastPage > 1"
      v-bind="{ paginatorInfo, next, prev, urlPrefix }"
      @navigate="scrollToTop"
    />

    <slot />

    <PaginatorNav
      v-if="bottomNav && paginatorInfo?.lastPage > 1"
      v-bind="{ paginatorInfo, next, prev, urlPrefix }"
      @navigate="scrollToTop"
    />
  </div>
</template>

<script setup lang="ts">
import type { PaginatorInfo } from '#gql/default'

interface Props {
  paginatorInfo: PaginatorInfo
  urlPrefix?: string
  topNav?: boolean
  bottomNav?: boolean
  next?: () => void
  prev?: () => void
}

withDefaults(defineProps<Props>(), {
  topNav: true,
  bottomNav: true,
  urlPrefix: '',
  next: () => {},
  prev: () => {},
})

/**
 * Scrolls the main element to the top position.
 * If the layout in layout/default is changed it should be adjusted
 */
function scrollToTop() {
  document.body.scrollTo(0, 0)
}

defineOptions({
  name: 'Paginator',
})
</script>
