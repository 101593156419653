import type {
  Raffle,
  FetchAdminRaffleQueryVariables,
  PaginatorInfo,
  RaffleEntry,
  RafflePrize,
} from '#gql/default'
import type { AsyncDataRequestStatus } from '#app/composables/asyncData'

interface AddOrUpdateRaffleInput {
  id?: string
  name: string
  cmsConfigId: string
  from: string
  to: string
  dailyRaffle: boolean
  active: boolean
  termsOfService?: string
}

interface AddOrUpdatePrizeInput {
  id?: string
  raffleId: string
  concessionId: string
  name: string
  description?: string
  day?: string
  active: boolean
}

export default async function useRaffleAdmin({
  variables,
}: { variables?: FetchAdminRaffleQueryVariables } = {}) {
  const raffleState = useState<any>('raffleAdmin', () => null)
  const status = useState<AsyncDataRequestStatus>(
    'raffleAdminStatus',
    () => 'idle'
  )

  const { backendToken } = useBackendToken()
  useGqlHeaders({ Authorization: `Bearer ${backendToken.value}` })

  async function fetchRaffle(variables: FetchAdminRaffleQueryVariables) {
    try {
      status.value = 'pending'
      const { data: responseData, error } = await useAsyncGql(
        'FetchAdminRaffle',
        variables
      )

      if (error.value) {
        status.value = 'error'
        return
      }

      raffleState.value = responseData.value
      status.value = 'success'
    } catch (e) {
      status.value = 'error'
      throw e
    }
  }

  if (variables && !raffleState.value?.raffle) {
    await fetchRaffle(variables)
  }

  const entries = computed(
    () => raffleState.value?.raffle?.entries as RaffleEntry[] | undefined
  )

  const pending = computed(() => status.value === 'pending')

  const paginatorInfo = computed(
    () =>
      raffleState.value?.raffle?.entries.paginatorInfo as
        | PaginatorInfo
        | undefined
  )

  async function addOrUpdatePrize(input: AddOrUpdatePrizeInput) {
    const result = await GqlRafflePrizeAddOrUpdate(input)
    const updatedPrize = result.rafflePrizeAddOrUpdate

    raffleState.value = {
      ...raffleState.value,
      raffle: {
        ...raffleState.value?.raffle,
        prizes: {
          ...raffleState.value?.raffle?.prizes,
          data: input.id
            ? raffleState.value?.raffle?.prizes?.data.map(
                (prize: RafflePrize) =>
                  prize.id === input.id ? updatedPrize : prize
              )
            : [
                ...(raffleState.value?.raffle?.prizes?.data || []),
                updatedPrize,
              ],
        },
      },
    }
    return updatedPrize
  }

  async function deletePrize(id: string) {
    const result = await GqlRafflePrizeDelete({ id })
    return result.rafflePrizeDelete
  }

  async function addOrUpdateRaffle(input: AddOrUpdateRaffleInput) {
    const result = await GqlRaffleAddOrUpdate(input)
    raffleState.value = {
      ...raffleState.value,
      raffle: result.raffleAddOrUpdate,
    }
    return result.raffleAddOrUpdate
  }

  async function deleteRaffle(id: string) {
    const result = await GqlRaffleDelete({ id })
    return result.raffleDelete
  }

  const days = computed(() => {
    if (!raffleState.value?.raffle?.from || !raffleState.value?.raffle?.to) {
      return []
    }

    const days = []
    const current = new Date(raffleState.value?.raffle?.from)
    const end = new Date(raffleState.value?.raffle?.to)

    while (current <= end) {
      days.push(new Date(current))
      current.setDate(current.getDate() + 1)
    }

    return days
  })

  async function fetchRaffleEntries(variables: { id: string, first: number, page?: number }) {
    try {
      status.value = 'pending'
      const { data: responseData, error } = await useAsyncGql(
        'FetchAdminRaffleEntries',
        variables
      )

      if (error.value) {
        status.value = 'error'
        return
      }

      if (raffleState.value?.raffle) {
        raffleState.value = {
          ...raffleState.value,
          raffle: {
            ...raffleState.value.raffle,
            entries: responseData.value.raffle.entries
          }
        }
      }

      status.value = 'success'
      return responseData.value.raffle.entries
    } catch (e) {
      status.value = 'error'
      throw e
    }
  }

  return {
    raffle: computed(() => raffleState.value?.raffle as Raffle | undefined),
    entries,
    pending,
    paginatorInfo,
    days,
    addOrUpdatePrize,
    deletePrize,
    addOrUpdateRaffle,
    deleteRaffle,
    fetchRaffle,
    fetchRaffleEntries,
  }
}
