export default function useScrollLockWithScrollBar() {
  const state = useState('scrollLock', () => ({
    isScrollLocked: false,
    scrollPosition: 0,
  }))

  function saveScrollPosition() {
    state.value.scrollPosition =
      -document.documentElement.scrollTop || -document.body.scrollTop
  }

  function restoreScrollPosition() {
    window.scrollTo(0, -state.value.scrollPosition)
  }

  function add() {
    if (state.value.isScrollLocked) {
      return
    }

    const body = document?.querySelector('body')
    if (!body) {
      return
    }

    state.value.isScrollLocked = true
    saveScrollPosition()
    body.style.setProperty('top', state.value.scrollPosition + 'px')
    body.classList.add('w-full', 'fixed', 'overflow-y-scroll')
  }

  function remove() {
    if (!state.value.isScrollLocked) {
      return
    }

    const body = document?.querySelector('body')
    if (!body) {
      return
    }

    state.value.isScrollLocked = false
    body.classList.remove('w-full', 'fixed', 'overflow-y-scroll')
    restoreScrollPosition()
    body.style.removeProperty('top')
  }

  function toggle() {
    state.value.isScrollLocked ? remove() : add()
  }

  return {
    isScrollLocked: computed(() => state.value.isScrollLocked),
    toggle,
    add,
    remove,
  }
}
