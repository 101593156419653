<template>
  <FormKit type="form" :id="formId" :actions="false" @submit="handleSubmit">
    <div class="space-y-4">
      <FormKit
        type="text"
        name="name"
        :label="t('label.name')"
        :value="raffle?.name"
        validation="required"
        :char-count="true"
      />

      <FormKit
        type="mask"
        name="from"
        :label="t('label.from')"
        mask="####-##-##"
        :value="raffle?.from"
        validation="required"
        help="JJJJ-MM-TT"
      />

      <FormKit
        type="mask"
        name="to"
        :label="t('label.to')"
        mask="####-##-##"
        :value="raffle?.to"
        validation="required"
        help="JJJJ-MM-TT"
      />

      <FormKit
        type="wysiwyg"
        name="termsOfService"
        :label="t('label.termsOfService')"
        :value="raffle?.termsOfService ?? ''"
      />

      <FormKit
        type="checkbox"
        name="dailyRaffle"
        :value="raffle?.dailyRaffle"
        :label="t('label.dailyRaffle')"
      />

      <FormKit
        type="checkbox"
        name="active"
        :value="raffle?.active"
        :label="t('label.active')"
      />

      <FormKit
        v-if="!!raffle"
        type="checkbox"
        name="delete"
        :label="t('label.delete')"
      />

      <div class="grid grid-cols-2 gap-6">
        <Button
          :theme="BUTTON_THEME.SECONDARY"
          :size="BUTTON_SIZE.SM"
          :text="t('btn.cancel')"
          @click="emit('cancel')"
        />

        <Button
          :theme="BUTTON_THEME.PRIMARY"
          :size="BUTTON_SIZE.SM"
          :text="!!raffle ? t('btn.update') : t('btn.add')"
          @click="submitForm(formId)"
        />
      </div>
    </div>
  </FormKit>
</template>

<script lang="ts" setup>
import { submitForm } from '@formkit/vue'
import useRaffleAdmin from '@builder/composables/use-raffle-admin'
import useMessage from '@base/composables/use-message'
import useCms from '@cms/composables/use-cms'
import { BUTTON_THEME, BUTTON_SIZE } from '@base/constants/button'
import { MESSAGE_SEVERITY } from '@base/constants/message'
import type { Raffle } from '#gql/default'

interface Props {
  raffle?: Raffle
}

const { raffle } = defineProps<Props>()
const { config } = useCms()
const { t } = useI18n()
const formId = useId()
const emit = defineEmits(['close', 'cancel'])
const { add: addMessage } = useMessage()

const { addOrUpdateRaffle, deleteRaffle } = await useRaffleAdmin()

async function handleSubmit(formData: any) {
  try {
    let message = ''

    if (raffle && formData.delete) {
      await deleteRaffle(raffle.id)
      message = t('toast.success.delete', { id: raffle.id })
    } else {
      const newRaffle = await addOrUpdateRaffle({
        ...formData,
        id: raffle?.id,
        active: formData.active ?? false,
        dailyRaffle: formData.dailyRaffle ?? false,
        cmsConfigId: config.value?.id,
      })

      message = !!raffle
        ? t('toast.success.update', { id: newRaffle.id })
        : t('toast.success.add', { id: newRaffle.id })
    }

    await refreshNuxtData()
    emit('close')
  } catch (error) {
    addMessage({ message: error.message, severity: MESSAGE_SEVERITY.ERROR })
    emit('close')
  }
}

defineOptions({
  name: 'RaffleForm',
})
</script>

<i18n>
de:
  label:
    name: "Name"
    from: "Von"
    to: "Bis"
    termsOfService: "Nutzungsbedingungen"
    dailyRaffle: "Tägliches Gewinnspiel"
    active: "Aktiv"
    delete: "Löschen"
  btn:
    cancel: "Abbrechen"
    add: "Hinzufügen"
    update: "Aktualisieren"
  toast:
    success:
      add: "Gewinnspiel {id} wurde erfolgreich hinzugefügt"
      update: "Gewinnspiel {id} wurde erfolgreich aktualisiert"
      delete: "Gewinnspiel {id} wurde erfolgreich gelöscht"
</i18n>
