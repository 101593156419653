export default function useBackendToken() {
  const runtimeConfig = useRuntimeConfig()
  const backendToken = useState<string | null>('backendToken')

  async function fetchToken({
    khkey,
    khuser,
  }: {
    khkey: string
    khuser: string
  }) {
    try {
      const data = await $fetch(runtimeConfig.public.BACKEND_API_TOKEN_URL, {
        method: 'post',
        body: {
          khuser,
          khkey,
        },
      })

      if (data) {
        backendToken.value = data as string
      }
    } catch {}
  }

  return {
    backendToken,
    fetchToken,
  }
}
