import type { FetchAdminRafflesQueryVariables, Raffle } from '#gql/default'

export default async function useRafflesAdmin({ variables }: { variables: FetchAdminRafflesQueryVariables }) {
  const { backendToken } = useBackendToken()
  
  // @TODO refactor into fetch which gets explicitly called
  useGqlHeaders({ Authorization: `Bearer ${backendToken.value}` })
  const { data, status } = await useAsyncGql(
    'FetchAdminRaffles',
    {
      ...variables,
      page: 1,
      first: 25,
    }
  )

  const raffles = computed(() => data.value?.raffles.data as Raffle[] | undefined)
  const paginatorInfo = computed(() => data.value?.raffles.paginatorInfo)
  const pending = computed(() => status.value === 'pending')


  return {
    raffles,
    pending,
    paginatorInfo
  }
}
