<template>
  <Teleport to="body">
    <div
      :class="cssClasses.container"
      :style="cssStyles.container"
      @click.self="close"
    >
      <div
        :class="cssClasses.modal"
        class="bg-light dark:bg-dark min-h-150 relative w-full max-w-xl rounded-lg p-0 shadow-xl"
      >
        <!-- Header -->
        <div
          class="border-neutral-light-3 dark:border-neutral-dark-1 relative mb-4 border-b p-2"
        >
          <h2 class="pr-8 text-2xl font-bold" v-text="t(modalTitle)" />

          <Button
            :theme="BUTTON_THEME.TERTIARY"
            :size="BUTTON_SIZE.SM"
            :title="t('btn.closeThisWindow')"
            :icon="SVG_ICON.CLOSE"
            :class="cssClasses.close"
            @click="close"
          />
        </div>

        <!-- Content -->
        <div v-if="!isPrizeFormVisible">
          <!-- Prize list -->
          <div v-if="raffle.prizes?.data?.length" class="mb-8 space-y-4">
            <AdminCard
              v-for="prize in raffle.prizes.data"
              :key="prize.id"
              :title="`${prize.quantity}x ${prize.name}`"
            >
              <template #content>
                <p
                  class="text-neutral-dark-1 dark:text-neutral-light-1 text-sm"
                >
                  {{ prize.description }}
                </p>
                <p class="text-sm" v-if="prize.day">
                  {{ t('raffle.prizeDay', { date: d(prize.day, 'date') }) }}
                </p>
              </template>

              <template #actions>
                <Button
                  :theme="BUTTON_THEME.SECONDARY"
                  :size="BUTTON_SIZE.SM"
                  :title="t('btn.edit')"
                  @click="showPrizeForm(prize)"
                >
                  <Icon name="material-symbols:edit-outline" />
                </Button>
              </template>
            </AdminCard>
          </div>
          <div
            v-else
            class="mb-8 space-y-4"
            v-text="t('errors.noPrizeFound')"
          />

          <Button
            :theme="BUTTON_THEME.PRIMARY"
            :size="BUTTON_SIZE.SM"
            :text="t('btn.add')"
            @click="addPrize"
            class="w-full"
          />
        </div>

        <RafflePrizesForm
          v-if="isPrizeFormVisible"
          :raffle
          :prize="currentPrize"
          @close="closePrizeForm"
        />
      </div>
      <div :class="cssClasses.backdrop" @click.self="close" />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import type { RafflePrize, Raffle } from '#gql/default'
import useModal from '@base/composables/use-modal'
import { BUTTON_THEME, BUTTON_SIZE } from '@base/constants/button'
import { SVG_ICON } from '@assets/constants/inline-svg'

interface Props {
  raffle: Raffle
  prize?: RafflePrize
}

const props = defineProps<Props>()
const emit = defineEmits(['close'])

const currentPrize = ref(props.prize)
const isPrizeFormVisible = ref(!!props.prize)

function showPrizeForm(prize?: RafflePrize) {
  isPrizeFormVisible.value = true
  currentPrize.value = prize
}

function addPrize() {
  isPrizeFormVisible.value = true
  currentPrize.value = undefined
}

async function closePrizeForm() {
  await refreshNuxtData()
  isPrizeFormVisible.value = false
  currentPrize.value = undefined
}

const { t, d } = useI18n()
const { close, open, cssClasses, cssStyles } = useModal({
  onClose: () => emit('close'),
})

open()

const modalTitle = computed(() => {
  if (!isPrizeFormVisible.value) return 'raffle.managePrizes'
  return currentPrize.value ? 'raffle.editPrize' : 'raffle.addPrize'
})

defineOptions({
  name: 'RaffleManagePrizesModal',
})
</script>

<i18n>
de:
  btn:
    closeThisWindow: "Fenster schließen"
    add: "Hinzufügen"
    edit: "Bearbeiten"
  raffle:
    managePrizes: "Preise verwalten"
    addPrize: "Preis hinzufügen"
    editPrize: "Preis bearbeiten"
    prizeDay: "Verfügbar am {date}"
  errors:
    noPrizeFound: "Es sind noch keine Preise für das Gewinnspiel angelegt worden."
</i18n>
