<template>
  <div
    class="dark:border-neutral-dark-1 border-neutral-light-1 rounded-lg border p-4 shadow-sm"
  >
    <slot name="default" :title-classes>
      <div :class="titleClasses">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div class="mt-2">
        <slot name="content" />
      </div>
      <div v-if="$slots.actions" class="mt-4">
        <slot name="actions" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title?: string
}

defineProps<Props>()

const titleClasses = 'dark:text-light text-dark text-sm font-medium'

defineOptions({
  name: 'AdminCard',
})
</script>
